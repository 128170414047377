import CheckIcon from '../../assets/check.svg';
import ErrorIcon from '../../assets/error.svg';

export const successIcon = {
  // @ts-ignore -- svgr config is not working properly, className is not in IntrinsictAttributes, but it compiles
  icon: <CheckIcon className={'icon icon--success'} />,
};
export const errorIcon = {
  // @ts-ignore -- svgr config is not working properly, className is not in IntrinsictAttributes, but it compiles
  icon: <ErrorIcon className={'icon icon--error'} />,
};
