import { KeyboardEvent } from 'react';

export const onEnter = (cb: () => void, chained?: () => void) => (e: KeyboardEvent) => {
  if (e.key === 'Enter') {
    cb();
  } else {
    if (chained) {
      chained();
    }
  }
};
