import { AnalyticsEvent, AnalyticsMethod, AppOrWeb, ClientId } from '@ytl/common-web';

const chain = () => {
  const c = new URL(window.location.href).searchParams.get('service');
  return c ? c : undefined;
};

// const capitalize = (s: string | undefined) => {
//   if (!!s || typeof s !== 'string') {
//     return undefined;
//   } else {
//     return s.charAt(0).toUpperCase() + s.slice(1);
//   }
// };

export const LoginAnalytics = {
  loginSuccess: (
    isWebView: boolean,
    method: AnalyticsMethod,
    mfa: boolean | undefined,
    accountId: string | undefined,
    uid: string | undefined,
  ) => {
    AnalyticsEvent('loginSuccess', {
      channel: AppOrWeb(isWebView),
      clientId: ClientId(),
      location: window.location.href,
      method: method,
      sso_chain: chain(),
      mfa: mfa ? mfa : false,
      accountId: accountId,
      userId: uid,
    });
  },

  loginError: (isWebView: boolean, method: AnalyticsMethod, mfa: boolean | undefined, error: string) => {
    AnalyticsEvent('loginError', {
      channel: AppOrWeb(isWebView),
      clientId: ClientId(),
      location: window.location.href,
      method: method,
      sso_chain: chain(),
      mfa: mfa ? mfa : false,
      errorType: error,
    });
  },

  loginFailure: (isWebView: boolean, method: AnalyticsMethod, mfa: boolean | undefined, error: string) => {
    AnalyticsEvent('loginFailed', {
      channel: AppOrWeb(isWebView),
      clientId: ClientId(),
      location: window.location.href,
      method: method,
      sso_chain: chain(),
      mfa: mfa ? mfa : false,
      errorType: error,
    });
  },
};
