import { LanguageProvider, ThemeProvider } from '@ytl/common-web';
import React from 'react';
import { translationEn, translationHu } from '../shared/data/translation';
import { AppProvider } from './appContext';
import { OpenAmProvider } from './openAmContext';

export type ContextProviderProps = { children: React.ReactNode };

const ContextProvider = ({ children }: ContextProviderProps) => {
  return (
    <ThemeProvider>
      <LanguageProvider
        translationHu={translationHu}
        translationEn={translationEn}
      >
        <AppProvider>
          <OpenAmProvider>{children}</OpenAmProvider>
        </AppProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default ContextProvider;
