import { Button, locationReload, NumericInput, onEnter, useTheme } from '@ytl/common-web';
import { useCallback, useState } from 'react';
import { useLanguage } from '../contexts/languageContext';
import { useOpenAm } from '../contexts/openAmContext';
import { LoginView, StageFailureType } from '../shared/@types/OpenAmStage';
import { useOpenAmStage } from '../shared/services/useOpenAmStage';
import { LoginAnalytics } from '../shared/utils/analytics';

export const SmsCodeStage = () => {
  const { translation } = useLanguage();
  const { isLoading, previousStage } = useOpenAm();
  const [smsCode, setSmsCode] = useState('');
  const { loginWithSMSCode } = useOpenAmStage();
  const [error, setError] = useState('');
  const { isWebView } = useTheme();

  const sendSmsCode = useCallback(async () => {
    try {
      await loginWithSMSCode(smsCode, (failure, isMfa) => {
        if (failure.stagefailure === StageFailureType.SmsInvalidSmsCode) {
          LoginAnalytics.loginError(isWebView, 'sms', isMfa, failure.stagefailure);
          setError(translation.loginRegister.login.box.loginError.general);
        }
      });
    } catch (err) {
      LoginAnalytics.loginError(isWebView, 'sms', false, 'error-sms-code');
      setError(translation.loginRegister.login.box.loginError.general);
    }
  }, [smsCode, loginWithSMSCode, setError, translation, isWebView]);

  return (
    <>
      <h3>{translation.loginRegister.login.box.headerSMS}</h3>

      <div className={'login-page__sms-code-input-container'}>
        <NumericInput
          label={translation.loginRegister.login.phone.code}
          name={'smscode'}
          type={'text'}
          value={smsCode}
          onChange={e => {
            setSmsCode(e.target.value);
            setError('');
          }}
          disabled={isLoading}
          maxLength={6}
          onKeyUp={onEnter(sendSmsCode)}
        />
      </div>

      {error && <div className="login-page__container__error">{error}</div>}

      <Button
        variant={'contained'}
        onClick={sendSmsCode}
        disabled={isLoading || smsCode.length < 6}
      >
        {translation.loginRegister.login.phone.login}
      </Button>

      {(previousStage?.view === LoginView.SMSPhoneNumber || previousStage?.view === LoginView.SMSCode) && (
        <Button
          variant={'text-undecorated'}
          onClick={() => locationReload()}
          className="login-page__container__back-button"
        >
          {translation.loginRegister.login.mfa.sms.backToPhoneNumber}
        </Button>
      )}
    </>
  );
};
