import { Language, LanguageContext } from '@ytl/common-web';
import { useContext } from 'react';
import { translationHu } from '../shared/data/translation';

export type Translation = typeof translationHu;

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error(`useLanguage must be used within a LanguageProvider`);
  }
  return {
    language: context.language,
    setLanguage: (language: Language) => {
      context.setLanguage(language);
      //set window location via history api. change locale query param
      const url = new URL(window.location.href);

      url.searchParams.set('locale', language);
      window.history.replaceState({}, '', url.toString());
    },
    translation: context.translation as Translation,
  };
};
