import { ButtonHTMLAttributes, useMemo } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children: string | React.ReactNode;
  variant?: 'contained' | 'outlined' | 'text' | 'text-undecorated';
  size?: 'small' | 'normal';
  color?: 'default' | 'danger';
}

export const Button = ({ variant, children, size, color, className, ...args }: ButtonProps) => {
  const cssClass = useMemo(() => {
    const classes = className ? [className] : [];
    if (!variant || variant === 'contained') {
      classes.push(`button--contained`);
    } else {
      classes.push(`button--${variant}`);
    }
    if (color === 'danger') {
      classes.push(`button--danger`);
    }
    if (size === 'small') {
      classes.push(`button--${size}`);
    }

    return classes.join(' ');
  }, [variant, size, color]);

  return (
    <button
      className={`button ${cssClass}`}
      {...args}
    >
      <>{children}</>
    </button>
  );
};
