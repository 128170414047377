import { useMask } from '@react-input/mask';

export const usePhoneNumberMask = () => {
  // debugger;
  return useMask({
    mask: 'pv ___ ____',
    replacement: {
      _: /\d/,
      p: /[2357]/,
      v: /0/,
      w: /[01]/,
    },
    separate: true,
    modify: value => {
      if (value.startsWith('3')) {
        return {
          mask: 'pw ___ ____',
          separate: false,
        };
      } else {
        return {
          mask: 'pv ___ ____',
          separate: false,
        };
      }
    },
  });
};
