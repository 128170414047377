import { Loader } from '@ytl/common-web';
import { useOpenAm } from '../contexts/openAmContext';
import { OpenAmAuthError } from '../shared/@types/OpenAmInitialData';
import { LoginView } from '../shared/@types/OpenAmStage';
import { AccountLockedError } from './AccountLockedError';
import { ErrorView } from './ErrorView';
import { LoginHero } from './LoginHero';
import { SmsCodeStage } from './SMSCodeStage';
import { SmsPhoneNumberStage } from './SMSPhoneNumberStage';
import { UserNamePasswordStage } from './UserNamePasswordStage';

export const LoginWithEmailAndSms = () => {
  const { stage, error } = useOpenAm();
  const view = stage.view;

  const showAuthorizationUI = !error || error === OpenAmAuthError.AccountIsLocked;

  return (
    <>
      <div className="login-page__wrapper">
        <div className="login-page__container">
          <div className={`login-page__container__box ${error ? 'login-page__container__box--error-view' : ''}`}>
            {showAuthorizationUI && (
              <>
                {view === LoginView.Loader && (
                  <div className="login-page__container__page-loader">
                    <Loader isLoading={true} />
                  </div>
                )}
                {view === LoginView.LoginForm && <UserNamePasswordStage />}
                {view === LoginView.SMSPhoneNumber && <SmsPhoneNumberStage />}

                {view === LoginView.SMSCode && <SmsCodeStage />}

                {error === OpenAmAuthError.AccountIsLocked && <AccountLockedError />}
              </>
            )}
            {!showAuthorizationUI && <ErrorView />}
          </div>
        </div>

        <LoginHero />
      </div>
    </>
  );
};
