import { ReactElement, ReactNode } from 'react';

export interface BoxProps {
  icon?: ReactElement;
  header?: string | ReactNode;
  headerHTML?: string;
  subHeader?: string | ReactNode;
  subHeaderHTML?: string;
  children?: ReactNode;
  action?: ReactElement;
  subAction?: ReactNode;
  className?: string;
}

export const Box = ({
  className,
  icon,
  header,
  headerHTML,
  subHeader,
  subHeaderHTML,
  children,
  action,
  subAction,
}: BoxProps) => {
  return (
    <>
      <div className={`box${className ? ` ${className}` : ''}`}>
        {!!icon && <div className={'box__icon'}>{icon}</div>}
        {!!header && <div className={'box__header'}>{header}</div>}
        {!!headerHTML && (
          <div
            className={'box__header'}
            dangerouslySetInnerHTML={{ __html: headerHTML }}
          />
        )}
        {!!subHeader && <div className={'box__sub-header'}>{subHeader}</div>}
        {!!subHeaderHTML && (
          <div
            className={'box__sub-header'}
            dangerouslySetInnerHTML={{ __html: subHeaderHTML }}
          />
        )}
        {!!children && <div className={'box__body'}>{children}</div>}
        {!!action && <div className={'box__action'}>{action}</div>}
        {!!subAction && <div className={'box__sub-action'}>{subAction}</div>}
      </div>
    </>
  );
};
