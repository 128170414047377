import { Box, BoxProps, Button, locationReload, NumericInput, onEnter } from '@ytl/common-web';
import { useCallback, useMemo, useState } from 'react';
import { useLanguage } from '../contexts/languageContext';
import { useOpenAm } from '../contexts/openAmContext';
import { LoginView, StageFailureType } from '../shared/@types/OpenAmStage';
import { useOpenAmStage } from '../shared/services/useOpenAmStage';

export const MfaAuthenticatorCode = () => {
  const { translation } = useLanguage();
  const { stage, isLoading } = useOpenAm();
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState('');

  const { loginWithAuthenticatorCode, previousStage } = useOpenAmStage();

  const sendCode = useCallback(async () => {
    try {
      await loginWithAuthenticatorCode(code, failure => {
        if (failure.stagefailure === StageFailureType.TotpInvalidCode) {
          setError(translation.loginRegister.login.box.loginError.invalidCode);
        }
      });
    } catch (err) {
      setError(translation.loginRegister.login.box.loginError.invalidCode);
    }
  }, [code, loginWithAuthenticatorCode, setError, translation]);

  const config = useMemo<BoxProps>(() => {
    if (stage.view === LoginView.MFAAuthenticatorCode) {
      return {
        header: translation.loginRegister.login.mfa.authenticator.header,
        subHeader: translation.loginRegister.login.mfa.authenticator.subHeader,
        children: (
          <>
            <NumericInput
              label={translation.loginRegister.login.mfa.authenticator.inputLabel}
              name={'authenticatorCode'}
              type={'text'}
              value={code}
              onChange={e => setCode(e.target.value)}
              disabled={isLoading}
              maxLength={6}
              onKeyUp={onEnter(sendCode, () => setError(''))}
              errorList={error ? [error] : undefined}
            />
          </>
        ),
        action: (
          <>
            <Button
              disabled={code.length < 6 || isLoading}
              onClick={sendCode}
            >
              {translation.loginRegister.login.mfa.authenticator.login}
            </Button>
          </>
        ),
      };
    }
    if (stage.view === LoginView.MFASMSCode) {
      const parsed = JSON.parse(stage.infoText?.at(0) || '{}');
      const phoneSuffix = translation.loginRegister.login.mfa.sms.subHeader(parsed.phoneNumberPostfix || '...');
      return {
        header: translation.loginRegister.login.mfa.sms.header,
        subHeader: phoneSuffix,
        children: (
          <>
            <NumericInput
              label={translation.loginRegister.login.mfa.sms.inputLabel}
              name={'smsCode'}
              type={'text'}
              value={code}
              onChange={e => setCode(e.target.value)}
              disabled={isLoading}
              maxLength={6}
              errorList={error ? [error] : undefined}
            />
          </>
        ),
        action: (
          <>
            <Button
              disabled={code.length < 6 || isLoading}
              onClick={sendCode}
            >
              {translation.loginRegister.login.mfa.sms.login}
            </Button>
            {previousStage?.view === LoginView.SMSPhoneNumber && (
              <Button
                variant={'text-undecorated'}
                onClick={() => locationReload()}
              >
                {translation.loginRegister.login.mfa.sms.backToPhoneNumber}
              </Button>
            )}
          </>
        ),
      };
    }
    throw new Error('invalid view code ' + stage.view);
  }, [stage, code, translation, isLoading, sendCode, error, previousStage]);

  return (
    <div className={'login-page__mfa-wrapper'}>
      <Box {...config} />
    </div>
  );
};
