import { AnalyticsMethod, useTheme } from '@ytl/common-web';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useOpenAm } from '../../contexts/openAmContext';
import {
  OpenAmAuthenticateError,
  OpenAmAuthenticateResponse,
  OpenAmAuthenticateResponseSuccess,
  OpenAmAuthError,
  StageFailure,
} from '../@types/OpenAmInitialData';
import { LoginView } from '../@types/OpenAmStage';
import { openAmResource } from '../resource/openAm.resource';
import { LoginAnalytics } from '../utils/analytics';
import { LoginUtils } from '../utils/loginUtils';

export const useOpenAmStage = () => {
  const {
    stage,
    setStage,
    previousStage,
    authIdRenewEnabled,
    setAuthIdRenewEnabled,
    setIsLoading,
    authParams,
    setError,
    loadInitialData,
  } = useOpenAm();

  const { isWebView } = useTheme();

  const isResponseSuccess = (response: any): response is OpenAmAuthenticateResponseSuccess => {
    return response.successUrl !== undefined && response.realm !== undefined && response.stage === undefined;
  };

  type FailureHandler = (res: StageFailure, mfa: boolean) => void;
  const handleOpenAmResponse = useCallback(
    (result: OpenAmAuthenticateResponse, method: AnalyticsMethod, failureHandler: FailureHandler) => {
      if (isResponseSuccess(result)) {
        LoginAnalytics.loginSuccess(isWebView, method, result.mfa, result.accountId, result.uid);

        setStage(LoginView.Loader, [], 'success');

        LoginUtils.navigateSuccess(authParams?.goto, result.successUrl, result.realm, () =>
          setError(OpenAmAuthError.OauthError),
        );
      } else {
        const failure = parseFailure(result.header);
        setStage(LoginUtils.getLoginViewByStage(result.stage), result.infoText, result.stage, failure);
        if (failure) {
          failureHandler(failure, result.mfa ? result.mfa : false);
        }
      }
    },
    [setStage, authParams, setError, isWebView],
  );

  const parseFailure = (f: string | undefined) => {
    try {
      if (f) {
        const res = JSON.parse(f);
        if (res.stagefailure && typeof res.stagefailure === 'string') {
          return res as StageFailure;
        }
      }
    } catch (e) {
      return undefined;
    }
  };

  const handleOpenAmErrorGeneric = useCallback(
    (err: unknown) => {
      if ((err as AxiosError).isAxiosError) {
        const axiosError = err as OpenAmAuthenticateError;
        if (axiosError.response?.data?.message === 'error-subscriptioncheck-subscription') {
          // setError(OpenAmAuthError.SubscriptionCheckError);
        } else if (axiosError.response?.data?.message === 'smstrustedlogin-callback-error') {
          // setError(OpenAmAuthError.SmsTrustedLoginCallbackError);
        } else if (axiosError.response?.status === 408) {
          setError(OpenAmAuthError.TimeoutError);
          return;
        } else {
          setError(OpenAmAuthError.LoginError);
        }
      } else {
        setError(OpenAmAuthError.LoginError);
      }
    },
    [setError],
  );

  const withTimeoutCheck = useCallback(
    async (func: () => Promise<OpenAmAuthenticateResponse>) => {
      try {
        return await func();
      } catch (err) {
        if ((err as AxiosError).isAxiosError) {
          const axiosError = err as OpenAmAuthenticateError;
          if (axiosError.response?.status === 408 && authIdRenewEnabled) {
            setAuthIdRenewEnabled(false);
            await loadInitialData();
            return await func();
          }
        }
        throw err;
      }
    },
    [setAuthIdRenewEnabled, authIdRenewEnabled, loadInitialData],
  );

  const loginFromStageWithUsernamePassword = useCallback(
    async (username: string, password: string, captchaValue: string, onFailure: FailureHandler) => {
      try {
        setIsLoading(true);
        const result = await withTimeoutCheck(() =>
          openAmResource.postLogin(stage.authId!, username, password, captchaValue),
        );
        setIsLoading(false);
        handleOpenAmResponse(result, 'email', onFailure);
        return result;
      } catch (err) {
        handleOpenAmErrorGeneric(err);
        setIsLoading(false);
        throw err;
      }
    },
    [setIsLoading, handleOpenAmResponse, handleOpenAmErrorGeneric, withTimeoutCheck, stage.authId],
  );

  const loginWithPhoneNumber = useCallback(
    async (phoneNumber: string, onFailure: FailureHandler) => {
      try {
        setIsLoading(true);

        const result = await withTimeoutCheck(() =>
          openAmResource.postPhoneNumber(stage.authId!, phoneNumber, stage.name!),
        );

        setIsLoading(false);

        handleOpenAmResponse(result, 'sms', onFailure);
      } catch (err) {
        handleOpenAmErrorGeneric(err);
        setIsLoading(false);
        throw err;
      }
    },
    [handleOpenAmResponse, setIsLoading, stage, handleOpenAmErrorGeneric, withTimeoutCheck],
  );

  const loginWithSMSCode = useCallback(
    async (smsCode: string, onFailure: FailureHandler) => {
      try {
        setIsLoading(true);
        const result = await withTimeoutCheck(() => openAmResource.postSMSCode(stage.authId!, smsCode));
        setIsLoading(false);
        handleOpenAmResponse(result, 'sms', onFailure);
      } catch (err) {
        handleOpenAmErrorGeneric(err);
        setIsLoading(false);
        throw err;
      }
    },
    [handleOpenAmResponse, setIsLoading, stage, handleOpenAmErrorGeneric, withTimeoutCheck],
  );

  const loginWithAuthenticatorCode = useCallback(
    async (authenticatorCode: string, onFailure: FailureHandler) => {
      try {
        setIsLoading(true);
        const result = await withTimeoutCheck(() =>
          openAmResource.postAuthenticatorCode(stage.authId!, authenticatorCode),
        );
        setIsLoading(false);
        handleOpenAmResponse(result, 'email', onFailure);
      } catch (err) {
        handleOpenAmErrorGeneric(err);
        setIsLoading(false);
        throw err;
      }
    },
    [handleOpenAmResponse, setIsLoading, stage, handleOpenAmErrorGeneric, withTimeoutCheck],
  );

  const loginWithOtpCode = useCallback(
    async (otpCode: string, onFailure: FailureHandler) => {
      try {
        setIsLoading(true);
        const result = await withTimeoutCheck(() => openAmResource.postYoloOtpCode(stage.authId!, otpCode));
        setIsLoading(false);
        handleOpenAmResponse(result, 'email', onFailure);
      } catch (err) {
        handleOpenAmErrorGeneric(err);
        setIsLoading(false);
        throw err;
      }
    },
    [handleOpenAmResponse, setIsLoading, stage, handleOpenAmErrorGeneric, withTimeoutCheck],
  );

  const reSendOtp = useCallback(
    async (onFailure: FailureHandler) => {
      try {
        setIsLoading(true);
        const result = await withTimeoutCheck(() => openAmResource.reSendYoloOtpCode(stage.authId!));
        setIsLoading(false);
        handleOpenAmResponse(result, 'email', onFailure);
      } catch (err) {
        handleOpenAmErrorGeneric(err);
        setIsLoading(false);
        throw err;
      }
    },
    [handleOpenAmResponse, setIsLoading, stage, handleOpenAmErrorGeneric, withTimeoutCheck],
  );

  return {
    loginFromStageWithUsernamePassword,
    loginWithPhoneNumber,
    loginWithSMSCode,
    loginWithAuthenticatorCode,
    loginWithOtpCode,
    previousStage,
    reSendOtp,
  };
};
