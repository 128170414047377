import { useMemo } from 'react';

// same as defined in yui/index.html
// @ts-ignore
const getParam = (q, paramName) => {
  try {
    const g = q?.get('goto');
    if (g) {
      const gparams = new URL(g).searchParams;
      if (gparams.has(paramName)) {
        return gparams.get(paramName);
      } else {
        const st = gparams.get('state');
        if (st) {
          return new URL(st).searchParams.get(paramName) || q.get(paramName);
        }
      }
    }
  } catch (e) {
    console.log('Invalid goto/state parameter', e);
  }
  return q?.get(paramName);
};

export const useModeParams = () => {
  return useMemo<{ isApp: boolean; isDark: boolean; isYolo: boolean }>(() => {
    const q = new URLSearchParams(window.location.search);
    const isApp = !!getParam(q, 'app');
    const isYolo = getParam(q, 'mode')?.toLowerCase() === 'yolo';
    const isDark = getParam(q, 'theme')?.toLowerCase() === 'dark';
    return { isApp, isDark, isYolo };
  }, [window.location]);
};

export const makeLink = (link: string | undefined, params: string | undefined) => {
  if (link) {
    const separator = link.includes('?') ? '&' : '?';
    if (params && params.length > 0) {
      return `${link}${separator}${params}`;
    } else {
      return link;
    }
  } else {
    return link;
  }
};
