import { Analytics, useTheme } from '@ytl/common-web';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { useOpenAm } from '../contexts/openAmContext';
import { LoginFrame } from '../partials/LoginFrame';
import { LoginWithEmailAndSms } from '../partials/LoginWithEmailAndSMS';
import { LoginYoloOtpState } from '../partials/LoginYoloOtpState';
import { MfaAuthenticatorCode } from '../partials/MFAAuthenticatorCode';
import { LoginView } from '../shared/@types/OpenAmStage';

export const LoginPage = () => {
  const { stage } = useOpenAm();
  const view = stage.view;

  const isMfaView = useMemo(() => [LoginView.MFAAuthenticatorCode, LoginView.MFASMSCode].includes(view), [view]);
  const displayFrame = ![LoginView.MFAAuthenticatorCode, LoginView.MFASMSCode, LoginView.YoloOtpStage].includes(view);
  const { isWebView } = useTheme();

  const displayedView = useMemo(() => {
    switch (view) {
      case LoginView.Loader:
      case LoginView.LoginForm:
      case LoginView.SMSPhoneNumber:
      case LoginView.SMSCode:
        return <LoginWithEmailAndSms />;
      case LoginView.MFAAuthenticatorCode:
      case LoginView.MFASMSCode:
        return <MfaAuthenticatorCode />;
      case LoginView.YoloOtpStage:
        return <LoginYoloOtpState />;
    }
  }, [view]);

  useEffect(() => {
    Analytics.pageView(isWebView);
  }, [isWebView]);

  useLayoutEffect(() => {
    if (isMfaView) {
      document.body.classList.add('yui--mfa');
    } else {
      document.body.classList.remove('yui--mfa');
    }
    return () => {
      document.body.classList.remove('yui--mfa');
    };
  }, [isMfaView]);

  return (
    <>
      <div className={`frame${isMfaView ? ' frame--mfa' : ''}`}>
        {displayFrame && <LoginFrame />}

        <div className="frame__content">
          <div className="login-page">{displayedView}</div>
        </div>
      </div>
    </>
  );
};
