import { useQueryParams } from '@ytl/common-web';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { OpenAmAuthError, StageFailure } from '../shared/@types/OpenAmInitialData';
import { InitParams, LoginView, OpenAmStage } from '../shared/@types/OpenAmStage';
import { openAmResource } from '../shared/resource/openAm.resource';
import { LoginUtils } from '../shared/utils/loginUtils';

export type AuthParams = {
  goto: string;
};

export type OpenAmContextProps =
  | undefined
  | {
      stage: OpenAmStage;
      setStage: (view: LoginView, infoText: string[], name: string, failure?: StageFailure) => void;
      previousStage?: OpenAmStage;
      loadInitialData: () => Promise<void>;
      initParams?: InitParams;
      authIdRenewEnabled: boolean;
      setAuthIdRenewEnabled: (authIdRenewEnabled: boolean) => void;
      setAccountLocked: () => void;
      error?: OpenAmAuthError;
      setError: (type?: OpenAmAuthError) => void;
      isLoading: boolean;
      setIsLoading: (isLoading: boolean) => void;
      authParams?: AuthParams;
    };

export const OpenAmContext = createContext<OpenAmContextProps>(undefined);

export const OpenAmProvider = ({ children }: { children: React.ReactNode }) => {
  const [stage, setStage] = useState<OpenAmStage>({
    view: LoginView.Loader,
    infoText: [],
  });
  const [previousStage, setPreviousStage] = useState<OpenAmStage>();
  const [initParams, setInitParams] = useState<InitParams>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<OpenAmAuthError | undefined>();
  const [authIdRenewEnabled, setAuthIdRenewEnabled] = useState(false);
  const [oauthParams, setOauthParams] = useState<AuthParams>();
  const query = useQueryParams();

  const updateStage = useCallback(
    (nextStage: OpenAmStage) => {
      setPreviousStage(stage);
      setStage(nextStage);
    },
    [stage, setPreviousStage, setStage],
  );

  const loadInitialData = useCallback(async () => {
    // if (1 + 1 === 2) {
    //   setStage({
    //     view: LoginView.YoloOtpStage,
    //   });
    //   return;
    // }

    // if (1 + 1 === 2) {
    //   setStage({
    //     view: LoginView.LoginForm,
    //   });
    //   setInitParams({
    //     forgottenPasswordLink: '',
    //     smsLoginLink: '/?view=sms-login',
    //     socialProviders: [
    //       { type: 'Google', link: 'https://google.com' },
    //       // { type: 'Apple', link: 'https://google.com' },
    //       // { type: 'Facebook', link: 'https://google.com' },
    //     ],
    //     registrationLink: '/registration',
    //   });
    //   return;
    // }

    try {
      const goto = new URLSearchParams(window.location.search).get('goto') || '';
      setOauthParams({
        goto,
      });
      try {
        const sessionCheckResult = await openAmResource.checkSession();
        if (sessionCheckResult.status === 200) {
          LoginUtils.navigateSuccess(
            goto,
            sessionCheckResult.data?.successURL,
            sessionCheckResult.data?.realm,
            () => {},
          );
          return;
        }
      } catch (e) {
        console.log('session check error', e);
      }
      const initialData = await openAmResource.getInitialData();
      if (initialData.data?.authId) {
        const isForcedSMSLogin = query.get('view') === 'sms-login';

        const view = isForcedSMSLogin
          ? LoginView.SMSPhoneNumber
          : LoginUtils.getLoginViewByStage(initialData.data?.stage);
        setAuthIdRenewEnabled(true);

        updateStage({
          view,
          authId: initialData.data?.authId,
          name: initialData.data?.stage,
          infoText: initialData.data?.infoText || [],
        });
        try {
          setInitParams(JSON.parse(initialData.data?.infoText[0]));
        } catch (err) {}
      } else {
        updateStage({
          view: LoginView.LoginForm,
          error: 'initial data error',
        });
      }
    } catch (err) {
      console.error(`Initial data loading error`, err);
      updateStage({
        view: LoginView.LoginForm,
      });
      setError(OpenAmAuthError.InitError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount, and when query changes
  }, [query]);

  const value: OpenAmContextProps = useMemo(
    () => ({
      stage,
      setStage: (view: LoginView, infoText: string[], name: string, failure?: StageFailure) => {
        setStage(prevState => {
          setPreviousStage(prevState);

          return {
            ...prevState,
            name,
            view,
            infoText,
            failure,
          };
        });
      },
      initParams,
      authIdRenewEnabled,
      setAuthIdRenewEnabled,
      loadInitialData,
      setAccountLocked: () => setError(OpenAmAuthError.AccountIsLocked),
      isLoading,
      setIsLoading,
      error,
      authParams: oauthParams,
      setError,
      previousStage,
    }),
    [
      stage,
      authIdRenewEnabled,
      isLoading,
      oauthParams,
      error,
      setError,
      initParams,
      loadInitialData,
      previousStage,
      setPreviousStage,
    ],
  );

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  return <OpenAmContext.Provider value={value}>{children}</OpenAmContext.Provider>;
};

export const useOpenAm = () => {
  const context = useContext(OpenAmContext);
  if (context === undefined) {
    throw new Error(`useOpenAm must be used within an OpenAmContext`);
  }
  return context;
};
