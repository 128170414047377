import { forwardRef, LegacyRef, ReactNode, useMemo } from 'react';
import ClearIcon from '../../assets/clear.svg';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactNode;
  name: string;
  required?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
  errorList?: Array<string | ReactNode>;
  warningList?: Array<string | ReactNode>;
  disabled?: boolean;
  clearable?: boolean;
  onClear?: () => void;
  ref?: LegacyRef<HTMLInputElement> | undefined;
}

// @ts-ignore -- typescript rollup plugin is throwing a warning here, because ...args is not resolved, because tslib is not found, but it compiles, so we can ignore it
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      required,
      name,
      hasError,
      errorList,
      warningList,
      hasWarning,
      disabled,
      clearable,
      onClear,
      ...args
    }: InputProps,
    ref,
  ) => {
    const cssClasses = useMemo(() => {
      const classes = [];
      if (required) {
        classes.push('form-input--required');
      }
      if (disabled) {
        classes.push('form-input--disabled');
      }
      if (hasError || (errorList && errorList.length > 0)) {
        classes.push('form-input--error');
      }
      if (hasWarning || (warningList && warningList.length > 0)) {
        classes.push('form-input--warning');
      }

      if (clearable) {
        classes.push('form-input--clearable');
      }
      return classes.join(' ');
    }, [required, disabled, hasError, errorList]);

    return (
      <>
        <div className={`form-input ${cssClasses}`}>
          <div className={'form-input__input-container'}>
            <label htmlFor={name}>{label}</label>
            <input
              name={name}
              disabled={disabled}
              ref={ref}
              {...args}
            />
          </div>
          {clearable && (
            <span
              className={'form-input__clear'}
              onClick={() => onClear && onClear()}
            >
              <ClearIcon />
            </span>
          )}
          {errorList && errorList.length > 0 && (
            <ul className={'form-input__error-list'}>
              {errorList.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          )}
          {warningList && warningList.length > 0 && (
            <ul className={'form-input__warning-list'}>
              {warningList.map((warning, index) => (
                <li key={index}>{warning}</li>
              ))}
            </ul>
          )}
        </div>
      </>
    );
  },
);
