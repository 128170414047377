import { Button, Input, onEnter, usePhoneNumberMask, useQueryParams, useTheme } from '@ytl/common-web';
import { AxiosError } from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { useLanguage } from '../contexts/languageContext';
import { useOpenAm } from '../contexts/openAmContext';
import { OpenAmAuthenticateError } from '../shared/@types/OpenAmInitialData';
import { LoginView, StageFailureType } from '../shared/@types/OpenAmStage';
import { useOpenAmStage } from '../shared/services/useOpenAmStage';
import { LoginAnalytics } from '../shared/utils/analytics';

export const SmsPhoneNumberStage = () => {
  const { translation } = useLanguage();
  const { isLoading, initParams } = useOpenAm();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const phoneNumberInput = usePhoneNumberMask();
  const query = useQueryParams();
  const { isWebView } = useTheme();

  const { loginWithPhoneNumber } = useOpenAmStage();

  const backToLogin = useMemo(() => {
    if (query.get('from') === LoginView.LoginForm) {
      return (
        <Button
          variant={'text-undecorated'}
          disabled={isLoading}
          onClick={() => window.history.back()}
          className="login-page__container__back-button"
        >
          {translation.loginRegister.login.phone.back}
        </Button>
      );
    }
  }, [query, isLoading, translation]);

  const stripPhoneNumber = (pn: string) => pn.replace(/ /g, '');

  const sendPhoneNumber = useCallback(async () => {
    try {
      await loginWithPhoneNumber(stripPhoneNumber(phoneNumber), (failure, isMfa) => {
        if (
          failure.stagefailure === StageFailureType.SubscriptioncheckSubscription ||
          failure.stagefailure === StageFailureType.SmsInvalidPhoneNumber
        ) {
          LoginAnalytics.loginFailure(isWebView, 'sms', isMfa, failure.stagefailure);
          setError(translation.loginRegister.login.box.loginError.subscriptionCheckError(initParams?.faqLink || '#'));
        }
      });
    } catch (err) {
      if ((err as AxiosError).isAxiosError) {
        const axiosError = err as OpenAmAuthenticateError;
        if (axiosError.response?.data?.message === StageFailureType.SubscriptioncheckSubscription) {
          LoginAnalytics.loginFailure(isWebView, 'sms', false, StageFailureType.SubscriptioncheckSubscription);
          setError(translation.loginRegister.login.box.loginError.subscriptionCheckError(initParams?.faqLink || '#'));
          return;
        }
      }
      LoginAnalytics.loginFailure(isWebView, 'sms', false, 'error-sms-phonenumber');
      setError(translation.loginRegister.login.box.loginError.general);
    }
  }, [phoneNumber, loginWithPhoneNumber, setError, translation, isWebView, initParams]);

  return (
    <>
      <h3>{translation.loginRegister.login.box.headerSMS}</h3>

      <div className={'login-page__phone-number-input-container'}>
        <Input
          label={translation.loginRegister.login.phone.phoneNumber}
          name={'phonenumber'}
          type={'text'}
          value={phoneNumber}
          onChange={e => {
            setPhoneNumber(e.target.value);
            setError('');
          }}
          disabled={isLoading}
          maxLength={11}
          onKeyUp={onEnter(sendPhoneNumber)}
          ref={phoneNumberInput}
          hasError={!!error}
        />
      </div>

      {error && (
        <div
          className="login-page__container__error"
          dangerouslySetInnerHTML={{ __html: error }}
        ></div>
      )}

      <Button
        variant={'contained'}
        onClick={sendPhoneNumber}
        disabled={isLoading || stripPhoneNumber(phoneNumber).length < 9}
      >
        {translation.loginRegister.login.phone.next}
      </Button>

      {backToLogin}
    </>
  );
};
