import { createContext, ReactNode, useCallback, useState } from 'react';
import { getCookie, setCookie } from '../utils';

export type Language = 'hu' | 'en';

export type LanguageContextProps<T> =
  | undefined
  | {
      language: Language;
      setLanguage: (language: Language) => void;
      translation: T;
    };

export type LanguageProviderProps<T> = {
  translationEn: T;
  translationHu: T;
  children: ReactNode;
};

export const LanguageContext = createContext<LanguageContextProps<any>>(undefined);

function fromSearchParams() {
  const localeParam = new URL(window.location.href).searchParams?.get('locale')?.toLowerCase();
  return localeParam && localeParam.match(/(^hu|^en)/) ? localeParam.substring(0, 2) : undefined;
}
const storedLanguage = fromSearchParams() || getCookie('lang');

const preferredLanguage = storedLanguage === 'hu' || storedLanguage === 'en' ? storedLanguage : 'hu';

export const LanguageProvider = <T,>({ children, translationHu, translationEn }: LanguageProviderProps<T>) => {
  const [language, setLanguage] = useState<Language>(preferredLanguage);
  const [translation, setTranslation] = useState(preferredLanguage === 'hu' ? translationHu : translationEn);

  const updateLanguage = useCallback((value: Language) => {
    if (value === 'hu') {
      setTranslation(translationHu);
    } else {
      setTranslation(translationEn);
    }
    setLanguage(value);
    setCookie('lang', value);
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage: updateLanguage, translation }}>
      {children}
    </LanguageContext.Provider>
  );
};
