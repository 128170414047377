import { Header } from '@ytl/common-web';
import { useCallback } from 'react';
import { useApp } from '../contexts/appContext';
import { useLanguage } from '../contexts/languageContext';

export const LoginFrame = () => {
  const { language, setLanguage } = useLanguage();
  const { menuItems } = useApp();
  const onLanguageChange = useCallback(() => {
    if (language === 'hu') {
      setLanguage('en');
    } else {
      setLanguage('hu');
    }
  }, [language, setLanguage]);

  return (
    <>
      <div className="frame__yui-header">
        <div className="frame__header">
          <Header
            mode={'b2c'}
            menuItems={menuItems}
            language={language}
            onLanguageClick={onLanguageChange}
          ></Header>
        </div>
      </div>
    </>
  );
};
