import { Dialog, DialogCloseReason, errorIcon, locationReload, useModeParams } from '@ytl/common-web';
import { useLanguage } from '../contexts/languageContext';
import { useOpenAm } from '../contexts/openAmContext';
import { OpenAmAuthError } from '../shared/@types/OpenAmInitialData';

export const AccountLockedError = () => {
  const { translation } = useLanguage();
  const { error, setError, initParams } = useOpenAm();
  const { isApp } = useModeParams();

  return (
    <>
      {error === OpenAmAuthError.AccountIsLocked && (
        <Dialog
          onClose={reason => {
            if (reason === DialogCloseReason.Cancel) {
              if (isApp && initParams?.closeWebViewLink) {
                window.location.href = initParams?.closeWebViewLink;
              } else {
                locationReload();
              }
            } else {
              if (initParams?.forgottenPasswordLink) {
                const link = initParams?.forgottenPasswordLink;
                window.location.href = `${link}${link.includes('?') ? '&' : '?'}lock=true`;
              } else {
                setError(OpenAmAuthError.InitError);
              }
            }
          }}
          title={translation.loginRegister.login.accountLocked.header}
          content={
            <span
              dangerouslySetInnerHTML={{
                __html: translation.loginRegister.login.accountLocked.content(
                  `${initParams?.videoChatLink ? initParams?.videoChatLink : '#'}`,
                ),
              }}
            />
          }
          okLabel={translation.loginRegister.login.accountLocked.sendConfirmEmail}
          cancelLabel={translation.loginRegister.login.accountLocked.cancel}
          verticalActions={true}
          cancelButtonVariant={'text'}
        />
      )}

      {error === OpenAmAuthError.OauthError && (
        <Dialog
          onClose={() => {
            // TODO
            // setOauthError('');
          }}
          {...errorIcon}
          // TODO oauth provider is unknown here
          titleHTML={translation.loginRegister.login.box.oauthError.header('')}
          content={translation.loginRegister.login.box.oauthError.content('')}
          okLabel={translation.loginRegister.login.box.oauthError.action}
        />
      )}

      {error === OpenAmAuthError.InitError && (
        <Dialog
          onClose={() => {
            locationReload();
          }}
          {...errorIcon}
          titleHTML={translation.loginRegister.login.failedToInit.header}
          content={translation.loginRegister.login.failedToInit.content}
          okLabel={translation.loginRegister.login.failedToInit.action}
        />
      )}

      {error === OpenAmAuthError.LoginError && (
        <Dialog
          onClose={() => {
            locationReload();
          }}
          {...errorIcon}
          titleHTML={translation.loginRegister.login.loginError.header}
          content={translation.loginRegister.login.loginError.content}
          okLabel={translation.loginRegister.login.loginError.action}
        />
      )}

      {error === OpenAmAuthError.TimeoutError && (
        <Dialog
          onClose={() => {
            locationReload();
          }}
          {...errorIcon}
          titleHTML={translation.loginRegister.login.loginError.header}
          content={translation.loginRegister.login.box.loginError.sessionTimeout}
          okLabel={translation.loginRegister.login.loginError.action}
        />
      )}
    </>
  );
};
