import { Button, errorIcon, locationReload } from '@ytl/common-web';
import { useMemo } from 'react';
import { useLanguage } from '../contexts/languageContext';
import { useOpenAm } from '../contexts/openAmContext';
import { OpenAmAuthError } from '../shared/@types/OpenAmInitialData';

export const ErrorView = () => {
  const { error } = useOpenAm();
  const { translation } = useLanguage();

  const icon = useMemo(() => {
    return errorIcon.icon;
  }, []);

  const header = useMemo(() => {
    switch (error) {
      case OpenAmAuthError.OauthError:
        return translation.loginRegister.login.box.oauthError.header('');
      case OpenAmAuthError.InitError:
        return translation.loginRegister.login.failedToInit.header;
      case OpenAmAuthError.LoginError:
        return translation.loginRegister.login.loginError.header;
      case OpenAmAuthError.TimeoutError:
        return translation.loginRegister.login.loginError.header;
      default:
        return translation.loginRegister.login.loginError.header;
    }
  }, [translation, error]);

  const content = useMemo(() => {
    switch (error) {
      case OpenAmAuthError.OauthError:
        return translation.loginRegister.login.box.oauthError.content('');
      case OpenAmAuthError.InitError:
        return translation.loginRegister.login.failedToInit.content;
      case OpenAmAuthError.LoginError:
        return translation.loginRegister.login.loginError.content;
      case OpenAmAuthError.TimeoutError:
        return translation.loginRegister.login.box.loginError.sessionTimeout;
      default:
        return translation.loginRegister.login.loginError.content;
    }
  }, [translation, error]);

  const button = useMemo(() => {
    switch (error) {
      case OpenAmAuthError.OauthError:
        return <Button variant={'contained'}>{translation.loginRegister.login.box.oauthError.action}</Button>;
      case OpenAmAuthError.InitError:
        return (
          <Button
            variant={'contained'}
            onClick={() => locationReload()}
          >
            {translation.loginRegister.login.failedToInit.action}
          </Button>
        );
      case OpenAmAuthError.LoginError:
        return (
          <Button
            variant={'contained'}
            onClick={() => locationReload()}
          >
            {translation.loginRegister.login.loginError.action}
          </Button>
        );
      case OpenAmAuthError.TimeoutError:
        return (
          <Button
            variant={'contained'}
            onClick={() => locationReload()}
          >
            {translation.loginRegister.login.loginError.action}
          </Button>
        );
      default:
        return (
          <Button
            variant={'contained'}
            onClick={() => locationReload()}
          >
            {translation.loginRegister.login.loginError.action}
          </Button>
        );
    }
  }, [translation, error]);

  return (
    <>
      {icon}
      <h3 dangerouslySetInnerHTML={{ __html: header }} />
      <p>{content}</p>
      {button}
    </>
  );
};
