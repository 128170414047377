import ContextProvider from './contexts';
import { LoginPage } from './pages/LoginPage';

function App() {
  return (
    <ContextProvider>
      <LoginPage />
    </ContextProvider>
  );
}

export default App;
