import { ReactNode, useMemo } from 'react';
import Select, { Props } from 'react-select';

export interface SelectProps<Option> extends Props<Option, false, any> {
  name: string;
  label: string | ReactNode;
  required?: boolean;
  hasError?: boolean;
  errorList?: Array<string>;
  options: Array<Option>;
}

const YtSelect = ({ required, isDisabled, hasError, name, label, options, errorList, ...args }: SelectProps<any>) => {
  const cssClasses = useMemo(() => {
    const classes = [];
    if (required) {
      classes.push('form-input--required');
    }
    if (isDisabled) {
      classes.push('form-input--disabled');
    }
    if (hasError || (errorList && errorList.length > 0)) {
      classes.push('form-input--error');
    }
    return classes.join(' ');
  }, [required, isDisabled, hasError, errorList]);

  return (
    <>
      <div className={`form-input form-input--select ${cssClasses}`}>
        <label htmlFor={name}>{label}</label>
        <Select
          options={options}
          isDisabled={isDisabled}
          name={name}
          components={{
            IndicatorSeparator: () => null,
          }}
          {...args}
        />
        {errorList && errorList.length > 0 && (
          <ul className={'form-input__error-list'}>
            {errorList.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export { YtSelect as Select };
