import { ReactElement, ReactNode } from 'react';
import { Button } from '../button/Button';

export interface DialogProps {
  title?: string | ReactNode;
  titleHTML?: string;
  subHeader?: string;
  subHeaderHTML?: string;
  content?: string | ReactNode;
  icon?: ReactElement;
  cancelLabel?: string;
  okLabel?: string;
  dangerLabel?: string;
  verticalActions?: boolean;
  cancelButtonVariant?: 'text' | 'outlined' | 'contained' | 'text-undecorated';
}

export enum DialogCloseReason {
  Cancel = 'cancel',
  Ok = 'ok',
  Danger = 'Danger',
}

export interface DialogWrapperProps extends DialogProps {
  onClose: (reason: DialogCloseReason) => void;
}

export const Dialog = ({
  onClose,
  icon,
  content,
  title,
  titleHTML,
  subHeader,
  subHeaderHTML,
  okLabel,
  cancelLabel,
  dangerLabel,
  verticalActions,
  cancelButtonVariant,
}: DialogWrapperProps) => {
  return (
    <>
      <div className="dialog-backdrop"></div>
      <div className="dialog-wrapper">
        <div className="dialog">
          <div className="dialog__icon">{icon}</div>
          {!!title && <div className="dialog__header">{title}</div>}
          {!!titleHTML && (
            <div
              className="dialog__header"
              dangerouslySetInnerHTML={{ __html: titleHTML }}
            />
          )}
          {!!subHeader && <div className="dialog__sub-header">{subHeader}</div>}
          {!!subHeaderHTML && (
            <div
              className="dialog__sub-header"
              dangerouslySetInnerHTML={{ __html: subHeaderHTML }}
            />
          )}
          <div className={`dialog__body${!content ? ' dialog__body--empty' : ''}`}>{!!content && content}</div>

          {(cancelLabel || okLabel || dangerLabel) && (
            <div className={`dialog__actions ${verticalActions ? 'dialog__actions--vertical' : ''}`}>
              {cancelLabel && (
                <Button
                  variant={cancelButtonVariant || 'outlined'}
                  onClick={() => onClose(DialogCloseReason.Cancel)}
                >
                  {cancelLabel}
                </Button>
              )}
              {okLabel && (
                <Button
                  variant={'contained'}
                  onClick={() => onClose(DialogCloseReason.Ok)}
                >
                  {okLabel}
                </Button>
              )}
              {dangerLabel && (
                <Button
                  variant={'contained'}
                  color={'danger'}
                  onClick={() => onClose(DialogCloseReason.Danger)}
                >
                  {dangerLabel}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
