import { AxiosError } from 'axios';

export type OpenAmInitialDataRequest = {
  realm: string;
  goto: string;
};

type OpenAmCallbackInputOutputItem<NAME extends string> = {
  name: NAME;
  value: string | number;
};

type OpenAmCallback<TYPE extends string, INPUT_NAME extends string, OUTPUT_NAME extends string> = {
  type: TYPE;
  input: [OpenAmCallbackInputOutputItem<INPUT_NAME>];
  output: [OpenAmCallbackInputOutputItem<OUTPUT_NAME>];
};
export type OpenAMSessionCheckResponse = {
  id: string;
  realm: string;
  dn: string;
  successURL: string;
  fullLoginURL: string;
};
export type OpenAMAuthenticateResponseBase = {
  stage: string;
  authId: string;
  template: string;
  header: string;
  infoText: Array<string>;
  accountId?: string;
  mfa?: boolean;
  uid?: string;
};
export type StageFailure = {
  stagefailure: string;
  isMfa: boolean;
};
export type OpenAmAuthenticateResponseOfLoginWithUsernamePassword = OpenAMAuthenticateResponseBase & {
  callbacks: [
    OpenAmCallback<'NameCallback', 'IDToken1', 'prompt'>,
    OpenAmCallback<'PasswordCallback', 'IDToken2', 'prompt'>,
    OpenAmCallback<'HiddenValueCallback', 'IDToken3', 'prompt'>,
  ];
};

export type OpenAmAuthenticateResponseOfLoginWithPhonenumber = OpenAMAuthenticateResponseBase & {
  callbacks: [OpenAmCallback<'NameCallback', 'IDToken1', 'prompt'>];
};

export type OpenAmAuthenticateResponseOfLoginWithSMSCode = OpenAMAuthenticateResponseBase & {
  callbacks: [OpenAmCallback<'NameCallback', 'IDToken1', 'prompt'>];
};

export type OpenAmAuthenticateResponseOfLoginWithAuthenticatorCode = OpenAMAuthenticateResponseBase & {
  callbacks: [OpenAmCallback<'NameCallback', 'IDToken1', 'prompt'>];
};

export type OpenAmAuthenticateResponseOfYoloOtpCode = OpenAMAuthenticateResponseBase & {
  callbacks: [
    OpenAmCallback<'NameCallback', 'IDToken1', 'prompt'>,
    OpenAmCallback<'ChoiceCallback', 'IDToken2', 'prompt'>,
  ];
};

export type OpenAmAuthenticateResponseSuccess = {
  stage: string;
  realm: string;
  successUrl: string;
  tokenId: string;
  accountId?: string;
  mfa?: boolean;
  uid?: string;
};

export type OpenAmAuthenticateResponse =
  | OpenAmAuthenticateResponseOfLoginWithUsernamePassword
  | OpenAmAuthenticateResponseOfLoginWithPhonenumber
  | OpenAmAuthenticateResponseOfLoginWithSMSCode
  | OpenAmAuthenticateResponseOfYoloOtpCode
  | OpenAmAuthenticateResponseSuccess;

export type OpenAmAuthenticateError = AxiosError & { response: { data: { message: string }; status: number } };

export enum OpenAmAuthError {
  AccountIsLocked = 'AccountIsLocked',
  OauthError = 'OauthError',
  InitError = 'InitError',
  LoginError = 'LoginError',
  TimeoutError = 'TimeoutError',
}
