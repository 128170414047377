import { LoginView } from '../@types/OpenAmStage';

export const LoginUtils = {
  getLoginViewByStage(stage: string): LoginView {
    if (stage === 'YettelAccount2') {
      return LoginView.LoginForm;
    }
    if (stage === 'YettelSubscriptionCheck2' || stage === 'YettelSms2') {
      return LoginView.SMSPhoneNumber;
    }
    if (stage === 'YettelSms4') {
      return LoginView.SMSCode;
    }
    if (stage === 'YettelTotp2') {
      return LoginView.MFAAuthenticatorCode;
    }
    if (stage === 'YettelYoloOtp2') {
      return LoginView.YoloOtpStage;
    }
    throw new Error(`Unknown stage ${stage}`);
  },
  navigateSuccess(goto: string | undefined, successUrl: string, realm: string, error: () => void) {
    if (goto && goto !== '') {
      try {
        window.location.href = goto;
      } catch (err) {
        error();
      }
    } else {
      const url = successUrl.startsWith('http') ? new URL(successUrl) : new URL(successUrl, window.location.origin);
      url.searchParams.has('realm') || url.searchParams.set('realm', realm);

      window.location.href = url.href;
    }
  },
};
