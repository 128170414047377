import { AxiosResponse } from 'axios';
import {
  OpenAmAuthenticateResponse,
  OpenAmAuthenticateResponseOfLoginWithAuthenticatorCode,
  OpenAmAuthenticateResponseOfLoginWithSMSCode,
  OpenAmAuthenticateResponseOfLoginWithUsernamePassword,
  OpenAmAuthenticateResponseOfYoloOtpCode,
  OpenAMSessionCheckResponse,
} from '../@types/OpenAmInitialData';
import { axiosInstance } from './axiosInstance';

// regisztracio url is a authenticate responsebol jon

const openAmJsonApiPrefix = `/openam/json/realms`;

const url = new URL(window.location.href);

const realm = (s => {
  const realm = s.get('realm') || '/';
  if (!realm || realm === '/') {
    return 'root';
  }
  return realm.replace('/', '');
})(url.searchParams);

const baseUrl = (() => {
  if (realm !== 'root') {
    return [openAmJsonApiPrefix, 'root', 'realms', realm, ''].join('/');
  }
  return [openAmJsonApiPrefix, 'root', ''].join('/');
})();

const service = (s => {
  return s.get('service');
})(url.searchParams);

const goto = (s => {
  return s.get('goto');
})(url.searchParams);

const theme = (s => {
  return s.get('theme');
})(url.searchParams);

const mode = (s => {
  return s.get('mode');
})(url.searchParams);

const app = (s => {
  return s.get('app');
})(url.searchParams);

const authenticateParams = [
  realm !== 'root' ? `realm=/${realm}` : null,
  service ? `service=${service}` : null,
  service ? `authIndexType=service` : null,
  service ? `authIndexValue=${service}` : null,
  goto ? `goto=${encodeURIComponent(goto)}` : null,
  theme ? `theme=${theme}` : null,
  mode ? `mode=${mode}` : null,
  app ? `app=${app}` : null,
  `ytr`,
].filter(Boolean);

const otpData = (authId: string, otpCode: string, resend: boolean = false): OpenAmAuthenticateResponseOfYoloOtpCode => {
  return {
    authId,
    template: '',
    stage: 'YettelYoloOtp2',
    header: '',
    infoText: [''],
    callbacks: [
      {
        type: 'NameCallback',
        output: [{ name: 'prompt', value: 'yolootp-code' }],
        input: [{ name: 'IDToken1', value: otpCode }],
      },
      {
        type: 'ChoiceCallback',
        output: [{ name: 'prompt', value: 'yolootp-action' }],
        input: [{ name: 'IDToken2', value: resend ? 1 : 0 }],
      },
    ],
  };
};

const postYolo = async (datas: OpenAmAuthenticateResponseOfYoloOtpCode): Promise<OpenAmAuthenticateResponse> => {
  return withAnalytics(
    await axiosInstance.post<any, AxiosResponse<OpenAmAuthenticateResponse>, OpenAmAuthenticateResponseOfYoloOtpCode>(
      `${baseUrl}authenticate?${authenticateParams.join('&')}`,
      datas,
    ),
  );
};
const header = (headers: Record<string, string>, key: string) => {
  const foundKey = Object.keys(headers)
    .map(k => k.toLowerCase())
    .find(k => k === key.toLowerCase());
  if (foundKey) {
    return headers[foundKey];
  } else {
    return undefined;
  }
};
const withAnalytics = (response: AxiosResponse<OpenAmAuthenticateResponse>): OpenAmAuthenticateResponse => {
  const accountId = header(response.headers, 'x-ynltx-ac');
  const mfa = header(response.headers, 'x-ynltx-mf');
  const userId = header(response.headers, 'x-ynltx-ui');
  // debugger;
  return {
    accountId: accountId ? accountId : undefined,
    mfa: !!mfa,
    uid: userId ? userId : undefined,
    ...response.data,
  };
};

export const openAmResource = {
  async checkSession() {
    return await axiosInstance.post<OpenAMSessionCheckResponse>(`/openam/json/users?_action=idFromSession`, {});
  },
  async getInitialData() {
    // console.log(`${baseUrl}authenticate?${authenticateParams.join('&')}`);
    // console.log(goto);
    // console.log(encodeURIComponent(goto || ''));
    return await axiosInstance.post<OpenAmAuthenticateResponseOfLoginWithUsernamePassword>(
      `${baseUrl}authenticate?${authenticateParams.join('&')}`,
      {},
    );
  },
  async postLogin(
    authId: string,
    email: string,
    password: string,
    captchaValue: string,
  ): Promise<OpenAmAuthenticateResponse> {
    return withAnalytics(
      await axiosInstance.post<
        any,
        AxiosResponse<OpenAmAuthenticateResponse>,
        OpenAmAuthenticateResponseOfLoginWithUsernamePassword
      >(`${baseUrl}authenticate?${authenticateParams.join('&')}`, {
        authId,
        template: '',
        stage: 'YettelAccount2',
        header: '',
        infoText: ['username', 'password'],
        callbacks: [
          {
            type: 'NameCallback',
            output: [{ name: 'prompt', value: 'E-mail cím' }],
            input: [{ name: 'IDToken1', value: email }],
          },
          {
            type: 'PasswordCallback',
            output: [{ name: 'prompt', value: 'Jelszó' }],
            input: [{ name: 'IDToken2', value: password }],
          },
          {
            type: 'HiddenValueCallback',
            output: [{ name: 'prompt', value: 'Jelszó' }],
            input: [{ name: 'IDToken3', value: captchaValue }],
          },
        ],
      }),
    );
  },
  async postPhoneNumber(authId: string, phoneNumber: string, stageName: string): Promise<OpenAmAuthenticateResponse> {
    return withAnalytics(
      await axiosInstance.post(`${baseUrl}authenticate?${authenticateParams.join('&')}`, {
        authId,
        template: '',
        stage: stageName,
        header: '',
        infoText: [''],
        callbacks: [
          {
            type: 'NameCallback',
            output: [{ name: 'prompt', value: 'sms-number' }],
            input: [{ name: 'IDToken1', value: phoneNumber }],
          },
        ],
      }),
    );
  },
  async postSMSCode(authId: string, smsCode: string): Promise<OpenAmAuthenticateResponse> {
    return withAnalytics(
      await axiosInstance.post<
        any,
        AxiosResponse<OpenAmAuthenticateResponse>,
        OpenAmAuthenticateResponseOfLoginWithSMSCode
      >(`${baseUrl}authenticate?${authenticateParams.join('&')}`, {
        authId,
        template: '',
        stage: 'YettelSms4',
        header: '',
        infoText: [''],
        callbacks: [
          {
            type: 'NameCallback',
            output: [{ name: 'prompt', value: 'yettel-sms-code' }],
            input: [{ name: 'IDToken1', value: smsCode }],
          },
        ],
      }),
    );
  },
  async postAuthenticatorCode(authId: string, authenticatorCode: string): Promise<OpenAmAuthenticateResponse> {
    return withAnalytics(
      await axiosInstance.post<
        any,
        AxiosResponse<OpenAmAuthenticateResponse>,
        OpenAmAuthenticateResponseOfLoginWithAuthenticatorCode
      >(`${baseUrl}authenticate?${authenticateParams.join('&')}`, {
        authId,
        template: '',
        stage: 'YettelTotp2',
        header: '',
        infoText: [''],
        callbacks: [
          {
            type: 'NameCallback',
            output: [{ name: 'prompt', value: 'totp-code' }],
            input: [{ name: 'IDToken1', value: authenticatorCode }],
          },
        ],
      }),
    );
  },

  async postYoloOtpCode(authId: string, otpCode: string): Promise<OpenAmAuthenticateResponse> {
    return postYolo(otpData(authId, otpCode));
  },

  async reSendYoloOtpCode(authId: string): Promise<OpenAmAuthenticateResponse> {
    return postYolo(otpData(authId, '', true));
  },

  // async getOauthRedirectUrl({ redirectUri, scope, clientId, state }: AuthParams) {
  //   const params = [
  //     'response_type=code',
  //     `redirect_uri=${redirectUri}`,
  //     scope ? `scope=${scope}` : '',
  //     state ? `&state=${state}` : '',
  //     `client_id=${clientId}`,
  //   ].filter(Boolean);
  //   return await axiosInstance.get(`${baseUrl}authenticate?${params.join('&')}`);
  // },
};
