import { Button, Input, InputMaxPasswordLength, makeLink, onEnter, useModeParams, useTheme } from '@ytl/common-web';
import { AxiosError } from 'axios';
import { Fragment, ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ReactComponent as AppleIcon } from '../assets/icon/apple.svg';
import { ReactComponent as SMSIcon } from '../assets/icon/chat-bubble.svg';
import { ReactComponent as ExclIcon } from '../assets/icon/exclamation-mark.svg';
import { ReactComponent as FacebookIcon } from '../assets/icon/facebook.svg';
import { ReactComponent as GoogleIcon } from '../assets/icon/google.svg';
import { useLanguage } from '../contexts/languageContext';
import { useOpenAm } from '../contexts/openAmContext';
import { LoginView, StageFailureType } from '../shared/@types/OpenAmStage';
import { useOpenAmStage } from '../shared/services/useOpenAmStage';
import { LoginAnalytics } from '../shared/utils/analytics';
import { isValidEmail } from '../shared/utils/emailValidator';
import { regLinkSetWorkaround } from '../shared/utils/regLinkSet';

export const UserNamePasswordStage = () => {
  const { language, translation } = useLanguage();
  const { isLoading, setAccountLocked, initParams } = useOpenAm();
  const { loginFromStageWithUsernamePassword } = useOpenAmStage();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [captchaValue, setCaptcha] = useState('');
  const [invalidUsername, setInvalidUsername] = useState(false);
  // const [nonEmailUsername, setNonEmailUsername] = useState(true);
  const [invalidPassword, setInvalidPassword] = useState(false);
  // const [emptyPassword, setEmptyPassword] = useState(true);
  const [loginError, setLoginError] = useState('');
  const [renderRecaptcha, setRenderRecaptcha] = useState(true);

  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const { isDark, isWebView } = useTheme();
  const { isYolo } = useModeParams();

  const linkParams = useMemo(() => {
    return [isDark ? `theme=dark` : '', isWebView ? `app=true` : '', isYolo ? `mode=yolo` : '', `locale=${language}`]
      .filter(s => !!s)
      .join('&');
  }, [isDark, isWebView, isYolo, language]);

  const forgottenPasswordLink = useMemo(() => {
    return makeLink(initParams?.forgottenPasswordLink, linkParams);
  }, [initParams, linkParams]);

  const registrationLink = useMemo(() => {
    return makeLink(initParams?.registrationLink, linkParams);
  }, [initParams, linkParams]);

  const socialProviders = useMemo(() => {
    return {
      google: (initParams?.socialProviders || []).find(provider => provider.type === 'Google'),
      facebook: (initParams?.socialProviders || []).find(provider => provider.type === 'Facebook'),
      apple: (initParams?.socialProviders || []).find(provider => provider.type === 'Apple'),
    };
  }, [initParams]);

  const captchaSiteKey = useMemo(() => {
    return initParams?.captchaSiteKey;
  }, [initParams]);

  const needToRenderCaptcha = renderRecaptcha && captchaSiteKey;
  const isCaptchaValid = !needToRenderCaptcha || !!captchaValue;

  const sendUserNamePassword = useCallback(async () => {
    if (!isValidEmail(username)) {
      setInvalidUsername(true);
      usernameRef.current?.focus();
      return;
    } else {
      setInvalidUsername(false);
    }

    if (!password) {
      setInvalidPassword(true);
      passwordRef.current?.focus();
      return;
    } else {
      setInvalidPassword(false);
    }

    try {
      await loginFromStageWithUsernamePassword(username, password, captchaValue, (failure, isMfa) => {
        if (failure.stagefailure === StageFailureType.AccountInvalidCredentials) {
          LoginAnalytics.loginFailure(isWebView, 'email', isMfa, StageFailureType.AccountInvalidCredentials);
          setLoginError(translation.loginRegister.login.box.loginError.invalidUserNameOrPassword);
        } else {
          LoginAnalytics.loginFailure(isWebView, 'email', isMfa, 'error-account-general');
          setLoginError(translation.loginRegister.login.box.loginError.general);
        }
      });
    } catch (err) {
      if ((err as AxiosError).isAxiosError) {
        const axiosError = err as AxiosError & { response: { data: { message: string }; status: number } };
        if (axiosError.response?.data?.message === StageFailureType.AccountInvalidCredentials) {
          LoginAnalytics.loginError(isWebView, 'email', false, StageFailureType.AccountInvalidCredentials);
          setLoginError(translation.loginRegister.login.box.loginError.invalidUserNameOrPassword);
        } else if (axiosError.response?.data?.message === 'error-account-captcha-invalid') {
          LoginAnalytics.loginError(isWebView, 'email', false, 'error-account-captcha-invalid');
          setLoginError(translation.loginRegister.login.box.loginError.invalidCaptcha);
        } else if (
          axiosError.response?.status === 403 ||
          axiosError.response?.data?.message === 'error-account-disabled'
        ) {
          LoginAnalytics.loginError(isWebView, 'email', false, 'error-account-disabled');
          setAccountLocked();
        } else {
          LoginAnalytics.loginFailure(isWebView, 'email', false, 'error-account-login');
          setLoginError(translation.loginRegister.login.box.loginError.general);
        }
      } else {
        LoginAnalytics.loginFailure(isWebView, 'email', false, 'error-account-login');
        setLoginError(translation.loginRegister.login.box.loginError.general);
      }
    }
  }, [
    translation,
    setAccountLocked,
    loginFromStageWithUsernamePassword,
    username,
    password,
    captchaValue,
    setLoginError,
    isWebView,
  ]);

  const onLoginWithSMSClicked = useCallback(() => {
    if (initParams?.smsLoginLink) {
      const target = initParams.smsLoginLink;

      window.location.href = `${target}${target.includes('?') ? '&' : '?'}from=${LoginView.LoginForm}&${linkParams}`;
    }
  }, [initParams, linkParams]);

  const onCaptchaChange = (value: any) => {
    setCaptcha(value);
  };

  const orSection: Array<{ children: ReactNode; key: string }> = useMemo(() => {
    const res: Array<{ children: ReactNode; key: string }> = [];

    if (socialProviders.google) {
      res.push({
        key: 'google',
        children: (
          <a
            className={'button button--outlined button--alternate-login'}
            href={socialProviders.google.link}
            rel="noopener noreferrer"
          >
            {translation.loginRegister.login.box.oauthGoogle}
            <GoogleIcon />
          </a>
        ),
      });
    }
    if (socialProviders.facebook) {
      res.push({
        key: 'facebook',
        children: (
          <a
            className={'button button--outlined button--alternate-login'}
            href={socialProviders.facebook.link}
            rel="noopener noreferrer"
          >
            {translation.loginRegister.login.box.oauthFacebook}
            <FacebookIcon />
          </a>
        ),
      });
    }
    if (socialProviders.apple) {
      res.push({
        key: 'apple',
        children: (
          <a
            className={'button button--outlined button--alternate-login'}
            href={socialProviders.apple.link}
            rel="noopener noreferrer"
          >
            {translation.loginRegister.login.box.oauthApple}
            <AppleIcon />
          </a>
        ),
      });
    }

    if (initParams?.smsLoginLink) {
      res.push({
        key: 'smsLogin',
        children: (
          <Button
            variant={'outlined'}
            onClick={onLoginWithSMSClicked}
            disabled={isLoading || !isCaptchaValid}
            className={'button--alternate-login'}
          >
            {translation.loginRegister.login.box.authSMS}
            <SMSIcon />
          </Button>
        ),
      });
    }

    if (registrationLink) {
      res.push({
        key: 'register',
        children: (
          <a
            className={'button button--text-undecorated login-page__container__box__register-link'}
            href={registrationLink}
            rel="noopener noreferrer"
          >
            {translation.loginRegister.login.box.register}
          </a>
        ),
      });
    }

    return res;
  }, [translation, socialProviders, initParams, onLoginWithSMSClicked, isLoading, isCaptchaValid, registrationLink]);

  useEffect(() => {
    setRenderRecaptcha(false);
  }, [language]);

  useEffect(() => {
    if (!renderRecaptcha) {
      setRenderRecaptcha(true);
    }
  }, [renderRecaptcha]);

  useLayoutEffect(() => {
    regLinkSetWorkaround(registrationLink);
  }, [registrationLink]);

  // const checkUsername = (e: KeyboardEvent) => {
  //   if (!isValidEmail(username)) {
  //     setNonEmailUsername(true);
  //   } else {
  //     setNonEmailUsername(false);
  //     if (!emptyPassword) {
  //       onEnter(sendUserNamePassword)(e);
  //     }
  //   }
  // };

  // const checkPassword = (e: KeyboardEvent) => {
  //   if (!password) {
  //     setEmptyPassword(true);
  //   } else {
  //     setEmptyPassword(false);
  //     if (!nonEmailUsername) {
  //       onEnter(sendUserNamePassword)(e);
  //     }
  //   }
  // };

  return (
    <>
      <h3>{translation.loginRegister.login.box.header}</h3>

      <div className="login-page__container__box__inputs">
        <Input
          label={<>{translation.loginRegister.login.box.email}</>}
          name={'username'}
          type={'text'}
          value={username}
          onChange={e => {
            setUsername(e.target.value);
            setLoginError('');
            setInvalidUsername(false);
          }}
          disabled={isLoading}
          onKeyUp={onEnter(sendUserNamePassword)}
          hasError={!!loginError || invalidUsername}
          ref={usernameRef}
        />

        <Input
          label={translation.loginRegister.login.box.password}
          name={'password'}
          type={'password'}
          value={password}
          onChange={e => {
            setPassword(e.target.value);
            setLoginError('');
            setInvalidPassword(false);
          }}
          disabled={isLoading}
          onKeyUp={onEnter(sendUserNamePassword)}
          hasError={!!loginError || invalidPassword}
          maxLength={InputMaxPasswordLength}
          ref={passwordRef}
        />

        {loginError && <div className="login-page__container__error">{loginError}</div>}
        {invalidUsername && (
          <div className="login-page__container__error">
            {translation.loginRegister.login.box.loginError.invalidEmail}
          </div>
        )}
        {invalidPassword && (
          <div className="login-page__container__error">
            {translation.loginRegister.login.box.loginError.invalidPassword}
          </div>
        )}
        {!loginError && isWebView && (
          <p className="login-page__container__box__password-hint">
            {translation.loginRegister.login.box.emailTooltip}
          </p>
        )}
      </div>

      {forgottenPasswordLink && (
        <div className="login-page__container__forgot-password-container">
          <a
            href={forgottenPasswordLink}
            className="button button--text"
            rel="noopener noreferrer"
          >
            {translation.loginRegister.login.box.forgottenPassword}
          </a>
        </div>
      )}

      {initParams?.businessLoginLink && (
        <div className="login-page__container__business-login">
          <ExclIcon />
          <div>
            <h5>{translation.loginRegister.login.box.business.title}</h5>
            <p
              dangerouslySetInnerHTML={{
                __html: translation.loginRegister.login.box.business.content(initParams.businessLoginLink),
              }}
            />
          </div>
        </div>
      )}

      {needToRenderCaptcha && (
        <ReCAPTCHA
          sitekey={captchaSiteKey}
          hl={language}
          onChange={onCaptchaChange}
          className={'login-page__container__recaptcha'}
        />
      )}

      <Button
        variant={'contained'}
        onClick={sendUserNamePassword}
        disabled={isLoading || !isCaptchaValid || invalidUsername || invalidPassword}
      >
        {translation.loginRegister.login.box.login}
      </Button>

      {orSection.length > 0 && (
        <>
          <div className="login-page__container__divider">
            <span>{translation.loginRegister.login.box.otherAuthHeader}</span>
          </div>

          {orSection.map(item => (
            <Fragment key={item.key}>{item.children}</Fragment>
          ))}
        </>
      )}
    </>
  );
};
