export const regLinkSetWorkaround = (registrationLink: string | undefined) => {
  try {
    const reglink = document.querySelector('a[href="/regisztracio"]');

    if (reglink && registrationLink) {
      reglink.setAttribute('href', registrationLink);
    }
  } catch (e) {
    console.error(e);
  }
};
