import { StageFailure } from './OpenAmInitialData';

export enum LoginView {
  Loader = 'loader',
  LoginForm = 'loginForm',
  SMSPhoneNumber = 'smsPhoneNumber',
  SMSCode = 'smsCode',
  MFAAuthenticatorCode = 'mfaAuthenticatorCode',
  // TODO this might not be used
  MFASMSCode = 'mfaSmsCode',
  YoloOtpStage = 'yoloOtpStage',
}

export enum StageFailureType {
  AccountInvalidCredentials = 'error-account-invalid-credentials',
  SubscriptioncheckSubscription = 'error-subscriptioncheck-subscription',
  SmsInvalidPhoneNumber = 'error-sms-invalid-phone-number',
  SmsInvalidSmsCode = 'error-sms-invalid-sms-code',
  TotpInvalidCode = 'error-totp-invalid-code',
  YolootpInvalidCode = 'error-yolootp-invalid-code',
}

export type OpenAmStage = {
  error?: string;
  authId?: string;
  name?: string;
  view: LoginView;
  infoText?: string[];
  failure?: StageFailure;
};

export type InitParams = {
  forgottenPasswordLink?: string;
  registrationLink?: string;
  smsLoginLink?: string;
  socialProviders?: Array<{
    type: 'Google' | 'Facebook' | 'Apple';
    link: string;
  }>;
  captchaSiteKey?: string;
  businessLoginLink?: string;
  closeWebViewLink?: string;
  videoChatLink?: string;
  faqLink?: string;
};
