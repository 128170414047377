import { Button, useTheme } from '@ytl/common-web';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLanguage } from '../contexts/languageContext';
import { useOpenAm } from '../contexts/openAmContext';
import { StageFailureType } from '../shared/@types/OpenAmStage';
import { useOpenAmStage } from '../shared/services/useOpenAmStage';
import { LoginAnalytics } from '../shared/utils/analytics';

export const LoginYoloOtpState = () => {
  const { translation } = useLanguage();
  const { isLoading } = useOpenAm();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const otpInput = useRef<HTMLInputElement>(null);

  const { loginWithOtpCode, reSendOtp } = useOpenAmStage();
  const { isWebView } = useTheme();

  const confirmYoloOTP = useCallback(
    async (otpCode: string) => {
      try {
        setError('');
        await loginWithOtpCode(otpCode, (failure, isMfa) => {
          if (failure.stagefailure === StageFailureType.YolootpInvalidCode) {
            LoginAnalytics.loginFailure(isWebView, 'email', isMfa, failure.stagefailure);
            setError(translation.loginRegister.otp.invalidCode);
          }
        });
      } catch (err) {
        setError(translation.loginRegister.otp.invalidCode);
      }
    },
    [setError, translation, loginWithOtpCode, isWebView],
  );

  const updateOtp = useCallback(
    (value: string) => {
      setOtp(value);
      setError('');
      if (value.length === 6) {
        confirmYoloOTP(value);
      }
    },
    [setOtp, confirmYoloOTP],
  );

  const sendAgainClicked = useCallback(() => {
    setOtp('');
    setError('');
    reSendOtp(_res => {});
  }, [setOtp, reSendOtp]);

  useLayoutEffect(() => {
    (global as any).$enterOtpCode = (code: string) => {
      updateOtp(code);
    };
  }, [updateOtp]);

  useEffect(() => {
    if (otpInput.current) {
      otpInput.current.focus();
    }
  }, []);

  return (
    <div className={'login-page__mfa-wrapper login-page__mfa-wrapper--yolo-otp'}>
      <p>{translation.loginRegister.otp.title}</p>

      <div className={`form-input otp-code-input ${!!error ? 'otp-code-input--error' : ''}`}>
        <div
          className="otp-code-input__value"
          onClick={() => otpInput.current?.focus()}
        >
          <span>{otp.charAt(0) || ''}</span>
          <span>{otp.charAt(1) || ''}</span>
          <span>{otp.charAt(2) || ''}</span>
          <span>{otp.charAt(3) || ''}</span>
          <span>{otp.charAt(4) || ''}</span>
          <span>{otp.charAt(5) || ''}</span>
        </div>
        <input
          ref={otpInput}
          value={otp}
          onChange={e => updateOtp(e.target.value)}
          type="number"
          pattern={'[0-9]*'}
        />
      </div>

      {error && <div className="login-page__mfa-wrapper__error">{error}</div>}

      <Button
        variant={'text-undecorated'}
        onClick={sendAgainClicked}
        disabled={isLoading}
      >
        {translation.loginRegister.otp.sendAgain}
      </Button>
    </div>
  );
};
