import React from 'react';
import LoaderIcon from '../../assets/loader.svg';

export const Loader = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <React.Fragment>
      {isLoading && (
        <span className={'loader'}>
          <LoaderIcon />
        </span>
      )}
    </React.Fragment>
  );
};
