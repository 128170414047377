import { useLanguage } from '../contexts/languageContext';
import { useOpenAm } from '../contexts/openAmContext';

export const LoginHero = () => {
  const { translation } = useLanguage();
  const { initParams } = useOpenAm();

  return (
    <>
      <div className="login-page__hero">
        <div className="login-page__hero__content">
          <h1>{translation.loginRegister.register.hero.header}</h1>
          <p
            dangerouslySetInnerHTML={{
              __html: translation.loginRegister.register.hero.desc(initParams?.videoChatLink),
            }}
          ></p>
        </div>
      </div>
    </>
  );
};
